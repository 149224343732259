import React, { FunctionComponent } from 'react';
import Markdown from 'react-markdown';

interface RichTextProps {
  data: {
    content: string;
  };
}

const RichText: FunctionComponent<RichTextProps> = ({ data }) => {
  return (
    <div className="prose lg:prose-xl dark:prose-dark dark:lg:prose-xl-dark container py-12 ">
      <Markdown>{data.content}</Markdown>
    </div>
  );
};

export default RichText;
