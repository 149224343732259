import React, { FunctionComponent } from 'react';
import FeatureColumn, { FeatureColumnType } from '../elements/feature-column';

interface FeatureColumnsGroupProps {
  data: {
    features: FeatureColumnType[];
  };
}

const FeatureColumnsGroup: FunctionComponent<FeatureColumnsGroupProps> = ({
  data,
}) => {
  return (
    <div className="container flex flex-col lg:flex-row lg:flex-wrap gap-12 align-top py-12">
      {data.features.map((feature) => (
        <FeatureColumn
          key={`feature_column__${feature.id}`}
          feature={feature}
        />
      ))}
    </div>
  );
};

export default FeatureColumnsGroup;
