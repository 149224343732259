import React, { FunctionComponent } from 'react';
import ButtonLink from '../../components/elements/button-link';
import { getButtonAppearance } from '../../utils/button';
import { ButtonLinkProp } from '../../utils/types';

interface BottomActionsProps {
  data: {
    title: string;
    buttons: ButtonLinkProp[];
  };
}

const BottomActions: FunctionComponent<BottomActionsProps> = ({ data }) => {
  return (
    <section className="bg-primary py-20 text-center">
      <h2 className="title text-white mb-10 heading2 uppercase">
        {data.title}
      </h2>
      {/* Buttons row */}
      <div className="container flex flex-row justify-center flex-wrap gap-4">
        {data.buttons.map((button) => (
          <ButtonLink
            button={button}
            appearance={getButtonAppearance(button.type, 'dark')}
            key={button.id}
          />
        ))}
      </div>
    </section>
  );
};

export default BottomActions;
