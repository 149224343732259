import React, { FunctionComponent } from 'react';
import Testimonial, { TestimonialType } from '../elements/testimonial';

interface TestimonialsGroupProps {
  data: {
    title: string;
    description: string;
    testimonials: TestimonialType[];
  };
}

const TestimonialsGroup: FunctionComponent<TestimonialsGroupProps> = ({
  data,
}) => {
  return (
    <section className="text-center text-lg bg-gray-200 dark:bg-deepblue pt-12 pb-16">
      <h2 className="heading2 uppercase mb-4 dark:text-textlightblue">
        {data.title}
      </h2>
      <p className="text-gray-700 dark:text-textlightblue mb-4">
        {data.description}
      </p>

      <div className="container">
        <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
          {data.testimonials.map((testimonial, i) => (
            <Testimonial
              key={`testimonial__${testimonial.id}`}
              testimonial={testimonial}
              left={i % 2 == 0}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsGroup;
