import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Image from '../image';
import CustomLink from '../elements/custom-link';
import { useElementOnScreen } from '../../utils/hooks';
import { MediaProp } from '../../utils/types';

import Markdown from 'react-markdown';

export interface TestimonialType {
  logo: MediaProp;
  picture: MediaProp;
  richText: string;
  authorName: string;
  authorTitle: string;
  link: string;
  id: string;
}

interface TestimonialProp {
  testimonial: TestimonialType;
}

interface TestimonialGenProp {
  testimonial: TestimonialType;
  left: boolean;
}

const TestimonialLeft: FunctionComponent<TestimonialProp> = ({
  testimonial,
}) => {
  const { containerRef, isVisible } = useElementOnScreen<HTMLDivElement>();
  return (
    <div className="flex flex-row-reverse md:contents">
      <div
        ref={containerRef}
        className={classNames(
          'bg-gradient-to-r from-primary to-gradientblue col-start-1 col-end-5 p-4 lg:p-8 my-4 ml-auto shadow-md sm:shadow-xl rounded-3xl ',
          {
            'opacity-0': !isVisible,
            'motion-safe:animate-fadeInSlow': isVisible,
          },
        )}
      >
        <Image
          style={{ maxWidth: '115px' }}
          media={testimonial.logo}
          className="mb-6 md:mb-10 mt-2 sm:mt-0"
        />
        <CustomLink
          link={{
            url: testimonial.link,
            newTab: true,
          }}
        >
          <h3 className="font-bold text-base md:text-sm hover:text-third text-left pb-4">
            {testimonial.authorName}
          </h3>
        </CustomLink>
        <Markdown className="mb-6 text-justify markdown list-disc prose lg:prose-xl text-white">
          {testimonial.richText}
        </Markdown>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-2 bg-secondary pointer-events-none"></div>
        </div>
        <div className="absolute top-1/2 -mt-3 -ml-5 rounded-full bg-secondary shadow">
          <div className="bg-secondary rounded-lg p-3 text-textdarkblue">
            {testimonial.authorTitle}
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialRight: FunctionComponent<TestimonialProp> = ({
  testimonial,
}) => {
  const { containerRef, isVisible } = useElementOnScreen<HTMLDivElement>();
  return (
    <div className="flex md:contents">
      <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-2 bg-secondary pointer-events-none"></div>
        </div>
        <div className="absolute top-1/2 -mt-3 -ml-5 rounded-full bg-secondary shadow">
          <div className="bg-secondary rounded-lg p-3 text-textdarkblue">
            {testimonial.authorTitle}
          </div>
        </div>
      </div>
      <div
        ref={containerRef}
        className={classNames(
          'bg-gradient-to-r from-primary to-gradientblue col-start-6 col-end-10 p-4 lg:p-8 rounded-3xl my-4 mr-auto shadow-md sm:shadow-xl ',
          {
            'opacity-0': !isVisible,
            'motion-safe:animate-fadeInSlow': isVisible,
          },
        )}
      >
        <Image
          style={{ maxWidth: '115px' }}
          media={testimonial.logo}
          className="mb-2 md:mb-10 mt-2 sm:mt-0"
        />
        <CustomLink
          link={{
            url: testimonial.link,
            newTab: true,
          }}
        >
          <h3 className="font-bold text-base md:text-sm hover:text-third text-left pb-4">
            {testimonial.authorName}
          </h3>
        </CustomLink>

        <Markdown className="mb-6 text-justify markdown list-disc prose lg:prose-xl text-white">
          {testimonial.richText}
        </Markdown>
      </div>
    </div>
  );
};

const Testimonial: FunctionComponent<TestimonialGenProp> = ({
  testimonial,
  left,
}) => {
  if (left) {
    return <TestimonialLeft testimonial={testimonial} />;
  } else {
    return <TestimonialRight testimonial={testimonial} />;
  }
};

export default Testimonial;
