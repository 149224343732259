import React, { FunctionComponent, useState } from 'react';
import Button from '@/components/elements/button';
import * as yup from 'yup';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { fetchAPI, strapiURL } from '@/utils/api';
import { ButtonLinkProp } from '@/utils/types';

interface FormValues {
  email: string;
}

interface LeadFormProps {
  data: {
    title: string;
    emailPlaceholder: string;
    location: string;
    submitButton: ButtonLinkProp;
  };
}

const LeadForm: FunctionComponent<LeadFormProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const LeadSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const initialValues: FormValues = {
    email: '',
  };

  return (
    <div className="py-10 text-center">
      <h1 className="text-3xl font-bold mb-2">{data.title}</h1>
      <div className="flex flex-col items-center">
        <Formik
          initialValues={initialValues}
          validationSchema={LeadSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setLoading(true);

            try {
              setErrors({ email: undefined });
              await fetchAPI('/lead-form-submissions', strapiURL, {
                method: 'POST',
                body: JSON.stringify({
                  email: values.email,
                  location: data.location,
                }),
              });
            } catch (err) {
              if (err instanceof Error) {
                setErrors({ email: err.message });
              }
            }

            setLoading(false);
            setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <div>
              <Form className="flex flex-col md:flex-row gap-4">
                <Field
                  className="text-base focus:outline-none py-4 md:py-0 px-4 border-2 rounded-md"
                  type="email"
                  name="email"
                  placeholder={data.emailPlaceholder}
                />
                <Button
                  type="submit"
                  appearance="white"
                  compact={false}
                  button={data.submitButton}
                  disabled={isSubmitting}
                  loading={loading}
                />
              </Form>
              {errors.email && touched.email && (
                <p className="text-red-500 h-12 text-sm mt-1 ml-2 text-left">
                  {errors.email}
                </p>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LeadForm;
