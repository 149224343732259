import { ArticleNode } from '@/models/articles';
import React, { FunctionComponent } from 'react';
import ArticleCard from './elements/article-card';

interface ArticlesProp {
  articles: ArticleNode[];
}

const Articles: FunctionComponent<ArticlesProp> = ({ articles }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {articles.map(({ node: article }) => {
        return (
          <ArticleCard article={article} key={`article__${article.slug}`} />
        );
      })}
    </div>
  );
};

export default Articles;
