import React, { FunctionComponent, useContext } from 'react';
import Image from '../image';
import classNames from 'classnames';
import { useElementOnScreen } from '@/utils/hooks';
import { MediaProp } from '@/utils/types';
import { useSelector } from 'react-redux';
import { State } from '@/store/store';
import { Theme } from '@/models/theme';

export interface FeatureColumnType {
  icon: MediaProp;
  darkIcon: MediaProp;
  id: string;
  title: string;
  description: string;
}

interface FeatureColumnProps {
  feature: FeatureColumnType;
}

const FeatureColumn: FunctionComponent<FeatureColumnProps> = ({ feature }) => {
  const { containerRef, isVisible } = useElementOnScreen<HTMLDivElement>();
  const colorTheme = useSelector<State, Theme>(
    (state) => state.theme.colorTheme,
  );
  return (
    <div
      ref={containerRef}
      className={classNames('flex-1 text-lg', {
        'opacity-0': !isVisible,
        'motion-safe:animate-fadeInSlow': isVisible,
      })}
      key={feature.id}
    >
      <Image
        media={colorTheme === 'dark' ? feature.icon : feature.darkIcon}
        className="w-10 dark:text-textlightblue"
      />
      <h3 className="font-bold mt-4 mb-4 dark:text-textlightblue uppercase">
        {feature.title}
      </h3>
      <p className="dark:text-textlightblue text-justify">
        {feature.description}
      </p>
    </div>
  );
};

export default FeatureColumn;
