import React, { FunctionComponent, useEffect } from 'react';
import Hero from './sections/hero';
import LargeVideo from './sections/large-video';
import FeatureColumnsGroup from './sections/feature-columns-group';
import FeatureRowsGroup from './sections/feature-rows-group';
import BottomActions from './sections/bottom-actions';
import SkillsGroup from './sections/skills-group';
import TestimonialsGroup from './sections/testimonials-group';
import RichText from './sections/rich-text';
import Pricing from './sections/pricing';
import LeadForm from './sections/lead-form';
import { useCookies } from 'react-cookie';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';
import BlogSection from './sections/blog-section';
import { PageContext } from '@/utils/types';

type SectionName =
  | 'sections.hero'
  | 'sections.large-video'
  | 'sections.feature-columns-group'
  | 'sections.feature-rows-group'
  | 'sections.bottom-actions'
  | 'sections.skills-group'
  | 'sections.testimonials-group'
  | 'sections.rich-text'
  | 'sections.pricing'
  | 'sections.lead-form'
  | 'sections.blog-section';

// Map Strapi sections to section components
const sectionComponents: {
  [K in SectionName]: FunctionComponent<any>;
} = {
  'sections.hero': Hero,
  'sections.large-video': LargeVideo,
  'sections.feature-columns-group': FeatureColumnsGroup,
  'sections.feature-rows-group': FeatureRowsGroup,
  'sections.bottom-actions': BottomActions,
  'sections.skills-group': SkillsGroup,
  'sections.testimonials-group': TestimonialsGroup,
  'sections.rich-text': RichText,
  'sections.pricing': Pricing,
  'sections.lead-form': LeadForm,
  'sections.blog-section': BlogSection,
};

interface PreviewModeBannerProps {
  location: {
    pathname: string;
  };
}

const PreviewModeBanner: FunctionComponent<PreviewModeBannerProps> = ({
  location,
}) => {
  return (
    <div className="py-4 bg-red-600 text-red-100 font-semibold uppercase tracking-wide">
      <div className="container">
        Preview mode is on.{' '}
        <button
          className="underline"
          onClick={() => {
            // The cookie will be deleted by a useEffect in the Section component
            navigate('/', { state: { prevPath: location.pathname } });
          }}
        >
          Turn off
        </button>
      </div>
    </div>
  );
};

export interface SectionData {
  id: string;
  strapi_component?: SectionName;
  __component: SectionName;
}

interface SectionProps {
  pageContext: PageContext;
  sectionData: SectionData;
}

// Display a section individually
const Section: FunctionComponent<SectionProps> = ({
  sectionData,
  pageContext,
}) => {
  // Prepare the component
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];

  if (!SectionComponent) {
    // No matching component for this page section
    return null;
  }

  // Display the section
  return <SectionComponent data={sectionData} context={pageContext} />;
};

interface LocationState {
  prevPath?: string;
}

interface SectionsProps {
  pageContext: PageContext;
  sections: SectionData[];
}

// Display the list of sections
const Sections: FunctionComponent<SectionsProps> = ({
  sections,
  pageContext,
}) => {
  const location = useLocation();
  // Ignore unused destructured variable
  // eslint-disable-next-line
  const [cookies, _, removeCookie] = useCookies(['strapiPreview']);

  useEffect(() => {
    const state = location.state as LocationState | undefined;
    // The preview cookie is deleted when state.prevPath exists on location
    if (state && state.prevPath) {
      removeCookie('strapiPreview', {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'strict',
      });
    }
  }, [location, removeCookie]);

  const previewModeIsEnabled =
    process.env.GATSBY_PREVIEW_SECRET &&
    cookies.strapiPreview === process.env.GATSBY_PREVIEW_SECRET;

  return (
    <div className="flex flex-col">
      {previewModeIsEnabled && <PreviewModeBanner location={location} />}
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          pageContext={pageContext}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </div>
  );
};

export default Sections;
