import React, { FunctionComponent } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ArticlesComponent from '../articles';
import { hero } from '@/styles/hero.module.css';
import classNames from 'classnames';
import { PageContext } from '@/utils/types';
import { ArticleNode } from '@/models/articles';

interface BlogSectionProps {
  data: {
    title: string;
    description: string;
  };
  context: PageContext;
}

interface BlogData {
  allStrapiArticle: {
    edges: ArticleNode[];
  };
}

const BlogSection: FunctionComponent<BlogSectionProps> = ({
  data,
  context,
}) => {
  const { locale } = context;

  const blogData: BlogData = useStaticQuery(query);
  const articles = blogData.allStrapiArticle.edges.filter(
    (article) => article.node.locale == locale,
  );

  return (
    <>
      <div
        className={classNames(
          // Common classes
          ' flex flex-col items-center justify-between py-12 z-0 bg-primary dark:bg-deepblue transition duration-500 text-white',
          hero,
        )}
      >
        <h1 className="heading1 mb-2">{data.title}</h1>
        <h2 className="c mb-2">{data.description}</h2>
      </div>
      <div className="container py-12 dark:text-textlightblue">
        <ArticlesComponent articles={articles} />
      </div>
    </>
  );
};

const query = graphql`
  query Articles {
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          strapiId
          slug
          locale
          title
          description
          categories {
            name
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 660)
              }
            }
          }
          author {
            name
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 30)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogSection;
