import React, { FunctionComponent } from 'react';
import Image from '../image';
import { hero } from '@/styles/hero.module.css';
import classNames from 'classnames';
import { MediaProp } from '@/utils/types';

interface HeroProps {
  data: {
    picture: MediaProp;
    label: string;
    title: string;
  };
}

const Hero: FunctionComponent<HeroProps> = ({ data }) => {
  return (
    <main
      className={classNames(
        // Common classes
        'container-index flex flex-col items-center justify-between py-12 z-0 bg-primary dark:bg-deepblue transition duration-500',
        hero,
      )}
    >
      <Image
        media={data.picture}
        className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 motion-safe:animate-fadeIn"
      />
      {/* Hero section label */}
      <p className="text-white uppercase tracking-wide font-semibold heading1 motion-safe:animate-fadeInSlow">
        {data.label}
      </p>
      {/* Big title */}
      <h1 className="text-white title mt-2 sm:mt-0 mb-4 sm:mb-2 heading2 motion-safe:animate-fadeInSlow">
        {data.title}
      </h1>
    </main>
  );
};

export default Hero;
