import React, { FunctionComponent } from 'react';
import FeatureRow, { FeatureRowType } from '../elements/feature-row';

interface FeatureRowsGroupProps {
  data: {
    features: FeatureRowType[];
  };
}

const FeatureRowsGroup: FunctionComponent<FeatureRowsGroupProps> = ({
  data,
}) => {
  return (
    <div className="container flex flex-col gap-12 py-12">
      {data.features.map((feature, index) => (
        <FeatureRow
          key={`feature_row__${feature.id}`}
          feature={feature}
          left={index % 2 === 0}
        />
      ))}
    </div>
  );
};

export default FeatureRowsGroup;
