import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Image from '../image';
import Video from './video';
import Markdown from 'react-markdown';
import { useElementOnScreen } from '@/utils/hooks';
import { MediaPropRemote } from '@/utils/types';

export interface FeatureRowType {
  id: string;
  title: string;
  richDescription: string;
  media: MediaPropRemote;
}

export interface FeatureRowProps {
  feature: FeatureRowType;
  left: boolean;
}

const FeatureRow: FunctionComponent<FeatureRowProps> = ({ feature, left }) => {
  const { containerRef, isVisible } = useElementOnScreen<HTMLDivElement>();
  return (
    <div
      ref={containerRef}
      className={classNames(
        // Common classes
        'flex flex-col justify-start md:justify-between md:items-center gap-10',
        {
          'lg:flex-row': left,
          'lg:flex-row-reverse': !left,
        },
      )}
      key={feature.id}
    >
      {/* Text section */}
      <div
        className={classNames('w-full lg:w-6/12 lg:pr-6 text-lg', {
          'opacity-0': !isVisible,
          'motion-safe:animate-fadeInSlow': isVisible,
        })}
      >
        <h3 className="dark:text-textlightblue title uppercase heading2">
          {feature.title}
        </h3>
        <Markdown className="dark:text-textlightblue my-6 text-justify">
          {feature.richDescription}
        </Markdown>
      </div>
      {/* Media section */}
      <div className="w-full sm:w-9/12 lg:w-4/12 max-h-full">
        {/* Images */}
        {feature.media.mime.startsWith('image') && (
          <Image media={feature.media} className="w-full h-auto" />
        )}
        {/* Videos */}
        {feature.media.mime.startsWith('video') && (
          <Video
            media={feature.media}
            className="w-full h-auto"
            autoPlay
            controls={false}
          />
        )}
      </div>
    </div>
  );
};

export default FeatureRow;
