import React, { FunctionComponent } from 'react';
import { getStrapiMedia } from '../../utils/media';
import { MediaPropRemote } from '../../utils/types';

interface VideoProps {
  media: MediaPropRemote;
  poster?: MediaPropRemote;
  className: string;
  controls?: boolean;
  autoPlay?: boolean;
}

const Video: FunctionComponent<VideoProps> = ({
  media,
  poster,
  className,
  controls = true,
  autoPlay = false,
}) => {
  const fullVideoUrl = getStrapiMedia(media.url);
  const fullPosterUrl = getStrapiMedia(poster?.url);

  if (fullVideoUrl != undefined && fullPosterUrl != undefined) {
    return (
      // TODO: Videos must have captions track
      // eslint-disable-next-line
      <video
        className={className}
        poster={fullPosterUrl}
        controls={controls}
        autoPlay={autoPlay}
      >
        <source src={fullVideoUrl} type={media.mime} />
      </video>
    );
  }

  return <div>No video</div>;
};

export default Video;
