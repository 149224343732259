import React, { FunctionComponent, useEffect, useState } from 'react';
import { useElementOnScreen } from '../../utils/hooks';
interface Level {
  level: number;
  name: string;
}

export interface SkillType {
  name: string;
  level: Level;
}

export interface SkillProps {
  skill: SkillType;
}

const Skill: FunctionComponent<SkillProps> = ({ skill }) => {
  const { containerRef, isVisible } = useElementOnScreen<HTMLDivElement>();
  const [width, setWidth] = useState('0%');
  useEffect(() => {
    if (isVisible) {
      let i = 0;
      const interval = setInterval(() => {
        i++;
        setWidth((i / 5.0) * skill.level.level + '%');
        if (i >= 100) {
          clearInterval(interval);
        }
      }, 10);
      return () => clearInterval(interval);
    } else {
      setWidth('0%');
    }
  }, [isVisible]);

  return (
    <div ref={containerRef} className="text-left">
      <div className="flex flex-row space-between flex-grow content-end items-end">
        <p>{skill.name}</p>
        <div className="flex-1"></div>
        <p className="text-sm">{skill.level.name}</p>
      </div>

      <div className="relative pt-1">
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-third200">
          <div
            style={{ width }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-third"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
