import React, { FunctionComponent } from 'react';
import Skill, { SkillType } from '../elements/skill';

interface SkillSection {
  name: string;
  skills: SkillType[];
}

interface SkillsGroupProps {
  data: {
    title: string;
    description: string;
    skillSections: SkillSection[];
  };
}

const SkillsGroup: FunctionComponent<SkillsGroupProps> = ({ data }) => {
  return (
    <section className="text-center text-lg bg-white dark:bg-darkblue pt-12 pb-16 dark:text-textlightblue ">
      <h2 className="heading2 uppercase mb-4 dark:text-textlightblue">
        {data.title}
      </h2>
      <p className="text-gray-700 dark:text-textlightblue mb-4">
        {data.description}
      </p>
      <div className="container text-left">
        {data.skillSections.map((skillSection) => {
          return (
            <div className="my-6" key={`skill_section__${skillSection.name}`}>
              <h4 className="heading3 mb-4">{skillSection.name}</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-2">
                {skillSection.skills.map((skill) => (
                  <Skill skill={skill} key={`skill__${skill.name}`}></Skill>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SkillsGroup;
